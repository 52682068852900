#breadcrumbs {
  background-color: #e9ecef;
}

.alert.header-message {
  border-radius: 0 !important;
}

.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  padding: 20px;
  transform: translate(-50%, -50%);
  color: white;
}
.slider-text h1 {
  color: white;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 600;
  font-family: sans-serif;
}
.slider-text h2 {
  color: white;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 500;
  font-family: sans-serif;
  text-align: center;
}

.carousel-item-background {
  background-position: center;
  width: 100vh;
  height: 100vw;
  background-size: cover;
}

body {
  margin: 0;
  padding: 0;
}

.icon-menu {
  top: 20px;
  height: 60px;
  width: 90px;
  padding: 20px;
  z-index: 80;
  position: fixed;
  cursor: pointer;
  transition: all 0.2s ease;
}

.hp-icon-logo {
  position: fixed;
  right: 20px;
  top: 20px;
  pointer-events: auto;
  cursor: pointer;
  z-index: 80;
}
.hp-icon-logo img {
  width: 55px;
}

.logo-incograf {
  position: fixed;
  right: 20px;
  bottom: 20%;
  pointer-events: auto;
  cursor: pointer;
  z-index: 80;
}
.logo-incograf img {
  width: 35px;
}

.error-content {
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
}
.error-content .error-number {
  color: gray;
  font-size: 3vw;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  margin: 0;
}
.error-content .error-message {
  position: relative;
}
.error-content .error-message.open .the-arrow-right {
  width: 100%;
  position: relative;
  transition: width 0.4s ease-in-out;
}
.error-content .error-message.open .the-arrow-right .shaft {
  background-color: #e3342f;
  display: block;
  height: 1px;
  width: 100%;
  position: relative;
  transition: width 0.4s ease-in-out;
  will-change: transform;
}
.error-content .error-message.open .the-arrow-right .shaft:before, .error-content .error-message.open .the-arrow-right .shaft:after {
  width: 3px;
  background-color: #e3342f;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: width 0.4s ease-in-out;
}
.error-content .error-message.open .the-arrow-right .shaft:before {
  transform: rotate(40deg);
  transform-origin: top right;
}
.error-content .error-message.open .the-arrow-right .shaft:after {
  transform: rotate(-40deg);
  transform-origin: bottom right;
}
.error-content .error-message .the-arrow-right {
  width: 0;
  position: relative;
  transition: width 0.4s ease-in-out;
}
.error-content .error-message .the-arrow-right .shaft {
  background-color: #e3342f;
  display: block;
  height: 1px;
  width: 0;
  position: relative;
  transition: width 0.4s ease-in-out;
  will-change: transform;
}
.error-content .error-message .the-arrow-right .shaft:before, .error-content .error-message .the-arrow-right .shaft:after {
  width: 3px;
  background-color: #e3342f;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: width 0.4s ease-in-out;
}
.error-content .error-message .the-arrow-right .shaft:before {
  transform: rotate(40deg);
  transform-origin: top right;
}
.error-content .error-message .the-arrow-right .shaft:after {
  transform: rotate(-40deg);
  transform-origin: bottom right;
}
.error-content .error-message .text {
  color: #e3342f;
  font-weight: 700;
  font-size: 3.5vw;
  text-transform: none;
  font-family: "Nunito", sans-serif;
  margin: 0;
}
.error-content .error-message .the-arrow-right {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}
.error-content .description {
  color: gray;
  font-size: 1.5vw;
  font-family: "Nunito", sans-serif;
}
.error-content .description a {
  text-decoration: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.error-content .description .the-arrow-right {
  width: 40px;
  position: relative;
  transition: width 0.4s ease-in-out;
}
.error-content .description .the-arrow-right .shaft {
  background-color: #e3342f;
  display: block;
  height: 1px;
  width: 40px;
  position: relative;
  transition: width 0.4s ease-in-out;
  will-change: transform;
}
.error-content .description .the-arrow-right .shaft:before, .error-content .description .the-arrow-right .shaft:after {
  width: 3px;
  background-color: #e3342f;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: width 0.4s ease-in-out;
}
.error-content .description .the-arrow-right .shaft:before {
  transform: rotate(40deg);
  transform-origin: top right;
}
.error-content .description .the-arrow-right .shaft:after {
  transform: rotate(-40deg);
  transform-origin: bottom right;
}
.error-content .description .link-text {
  padding-right: 10px;
  color: gray;
  font-size: 1vw;
  font-family: "Nunito", sans-serif;
}

@media only screen and (max-width: 1200px) {
  .error-content .error-number {
    font-size: 7vw;
  }
  .error-content .error-message .text {
    font-size: 6vw;
  }
  .error-content .description {
    font-size: 2vw;
  }
  .error-content .description .link-text {
    font-size: 1.5vw;
  }
}
@media only screen and (max-width: 992px) {
  .error-content .error-number {
    font-size: 8vw;
  }
  .error-content .error-message .text {
    font-size: 7vw;
  }
  .error-content .description {
    font-size: 3vw;
  }
  .error-content .description .link-text {
    font-size: 2.5vw;
  }
}
@media only screen and (max-width: 576px) {
  .error-content .error-number {
    font-size: 10vw;
  }
  .error-content .error-message .text {
    font-size: 9vw;
  }
  .error-content .description {
    font-size: 5vw;
  }
  .error-content .description .link-text {
    font-size: 4.5vw;
  }
}
@media only screen and (min-width: 1900px) {
  .error-content .error-number {
    font-size: 70px;
  }
  .error-content .error-message .text {
    font-size: 70px;
  }
  .error-content .description {
    font-size: 40px;
  }
  .error-content .description .link-text {
    font-size: 20px;
  }
}