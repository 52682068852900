#breadcrumbs {
    background-color: #e9ecef;
}

.alert.header-message {
    border-radius: 0 !important;
}
.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  padding: 20px;
  transform: translate(-50%,-50%);
  color: white;

   h1 {
    color: white;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 600;
    font-family: sans-serif;
  }
  h2 {
    color: white;
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 500;
    font-family: sans-serif;
    text-align: center;
  }
}
.carousel-item-background{
  background-position: center;
  width: 100vh;
  height:100vw ;
  background-size: cover;
}

@mixin servicesArrow($widthArrow){
  .the-arrow-right {
    width: $widthArrow;
    position: relative;
    transition: width 0.4s ease-in-out;

    .shaft {
      background-color: $red;
      display: block;
      height: 1px;
      width: $widthArrow;
      position: relative;
      transition: width 0.4s ease-in-out;
      will-change: transform;

      &:before,
      &:after {
        width: 3px;
        background-color: $red;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        top: 0;
        right: 0;
        transition: width 0.4s ease-in-out;
      }

      &:before {
        transform: rotate(40deg);
        transform-origin: top right;
      }

      &:after {
        transform: rotate(-40deg);
        transform-origin: bottom right;
      }
    }
  }
}
